import React from "react";
const PortfolioCard = ({ data, index }) => {
  const { image, title, categories, url } = data;

  let url1 =
    title == "Medjool Dates" ? "/service-details" : "/service-detailss";

  return (
    <div className={`singlefolio`}>
      <img src={image} alt={title} />
      <div className="folioHover">
        <a className="cate" href="#">
          {categories?.map(cat => cat + ", ")}
        </a>
        <h4>
          <a href={url}>{title}</a>
        </h4>
      </div>
    </div>
  );
};

export default PortfolioCard;
